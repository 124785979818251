import { Dispatch, SetStateAction } from 'react';

import { authAxios } from '../../utils/session_utils';
import { message } from 'antd';

export const useBatchJobsActionHook = (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
	const handleUploadSFClick = async (data: any) => {
		const config: any = {
			method: "POST",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/batch-jobs/upload-autopodder-prelogs`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify({
				programName: data.programName,
				strategyName: data.strategyName,
				batchJobId: data.batchJobId,
			}),
		};
		const response = await authAxios(config);
		if (response.status === 200) {
			message.success(response.data.message + ' ' + response.data.result.Location);
		} else {
			message.error(response.data.message);
		}

		setIsLoading(false);
	}


	return { handleUploadSFClick, setIsLoading };
};

