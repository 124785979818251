import { FormInstance } from "rc-field-form";
import { Dispatch, SetStateAction } from "react";
import { authAxios } from "../../utils/session_utils";
import { message } from "antd";
import { setDistributionFormItems } from "../../captions/hooks/utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";

export const editHelper = (
	record: any,
	form: FormInstance,
	setCuePointEditId: Dispatch<SetStateAction<string | undefined | null>>,
	isCloneEditor: boolean | undefined,
	setIsDuplicateCuePointFormOpen: Dispatch<SetStateAction<boolean>>,
	setIsCuePointFormOpen: Dispatch<SetStateAction<boolean>>,
	setDistributionCheckedList: Dispatch<SetStateAction<CheckboxValueType[]>>,
	setCuepointFields: Dispatch<SetStateAction<string[]>>,
	setEpisodeId?: Dispatch<SetStateAction<string | undefined | null>>
) => {
	const { ref_id, episode_id, id, cue_points, cuepoint_distribution_ids, offset_time } = record;
	setEpisodeId && setEpisodeId(episode_id);
	setCuePointEditId(id);
	if (isCloneEditor) {
		setIsDuplicateCuePointFormOpen(true);
	}
	setIsCuePointFormOpen(true);
	const distributionIds = setDistributionFormItems(cuepoint_distribution_ids);
	setDistributionCheckedList(distributionIds);
	const cuepointFieldsData: any = {};
	const cuepointFields: string[] = [];
	cue_points?.split(",")?.forEach((cuepoint: string, i: number) => {
		cuepointFieldsData["cue_points" + (i + 1)] = cuepoint;
		cuepointFields.push(`${i + 1}`);
	});
	setCuepointFields(cuepointFields);
	console.log({ cuepointFieldsData });
	form.setFieldsValue({
		...cuepointFieldsData,
		cuepoint_ref_id: ref_id,
		cuepoint_offset_time: offset_time,
	});
};

export const saveUpdateCuePoints = async (
	values: any,
	episode_id: string | null | undefined,
	distributionCheckedList: CheckboxValueType[],
	cuepointFields: string[],
	create5SecOffset: boolean,
	editId?: string | null
) => {
	try {
		const { cuepoint_offset_time, offset_for_clone } = values;
		const cue_points = cuepointFields.map(key => values["cue_points" + key]);
		const data: any = {
			cuePointsData: {
				create5SecOffset: create5SecOffset && !offset_for_clone,
				cue_points: cue_points.filter(cuepoint => cuepoint).join(","),
				episode_id,
				cuepoint_distribution: distributionCheckedList,
				offset_time: cuepoint_offset_time ? cuepoint_offset_time : 0,
				offset_for_clone,
			},
		};
		if (editId) {
			data["cuePointsData"]["id"] = editId;
		}
		const config = {
			method: editId && !offset_for_clone ? "PUT" : "POST",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/cuepoints`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(data),
		};
		const response = await authAxios(config);
		if (response.status === 200) {
			message.success(response.data.message);
		}
	} catch (error) {
		message.error("Something went wrong");
		console.error({ error });
		throw new Error(error as string);
	}
};

export const deleteCuePoint = async (id: string) => {
	const config = {
		method: "DELETE",
		url: `${process.env.REACT_APP_BACK_END_API}/snowflake/cuepoints/${id}`,
	};
	const response = await authAxios(config);
	return response;
};
