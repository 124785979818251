import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';


interface UploadProgressProps {
    uploadStatus: string;
  }
  
   
const UploadProgress: React.FC<UploadProgressProps> = ({ uploadStatus }) => {
const [percent, setPercent] = useState(0);

useEffect(() => {
    const basePercent = 
      uploadStatus === 'completed' ? 100 :
      uploadStatus === 'viewership_fetched' ? 90 :
      uploadStatus === 'uploaded' ? 20 :
      uploadStatus === 'checking_for_dupes' ? 10 : 0;
   
    const nextBasePercent = 
      uploadStatus === 'completed' ? 100 :
      uploadStatus === 'viewership_fetched' ? 100 :
      uploadStatus === 'uploaded' ? 90 :
      uploadStatus === 'checking_for_dupes' ? 20 : 10;
   
    const interval = setInterval(() => {
      setPercent(prev => {
        if (prev >= nextBasePercent) return prev;
        const increment = Math.random() * 0.5;
        return Math.min(prev + increment, nextBasePercent);
      });
    }, 200);
   
    return () => clearInterval(interval);
   }, [uploadStatus]);


return (
    <Progress 
    percent={Math.round(percent)}
    status={uploadStatus === 'error' ? 'exception' : 'active'}
    strokeColor={{
        '0%': '#108ee9',
        '100%': '#87d068',
    }}
    />
);
};
   

export default UploadProgress;