import React from 'react';
import { Modal, Row, Col, Select, DatePicker, Input, Typography, Button, Form } from 'antd';
import moment, { Moment } from 'moment';

const { Title } = Typography;
const { Option } = Select;

interface AddInvoiceModalProps {
  open: boolean;
  onCancel: () => void;
  onSave: (values: any) => void;
  dropdownValues: any;
  invoiceForm: any;
  handleInvoiceFormChange: (field: string, value: any) => void;
}

const AddInvoiceModal: React.FC<AddInvoiceModalProps> = ({
  open,
  onCancel,
  onSave,
  dropdownValues,
  invoiceForm,
  handleInvoiceFormChange,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('values: ', values)
    if (values.paymentDate && moment.isMoment(values.paymentDate)) {
      values.paymentDate = values.paymentDate.format('MM/DD/YYYY');
    }
    onSave(values);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Multi-Line "
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ...invoiceForm,
          paymentDate: invoiceForm.paymentDate ? moment(invoiceForm.paymentDate, 'MM/DD/YYYY') : null,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="domain"
              label={<Title level={5}>Domain <span style={{ color: 'red' }}>*</span></Title>}
              rules={[{ required: true, message: 'Please select a domain' }]}
            >
              <Select
                placeholder="Select Domain"
                onChange={(value) => handleInvoiceFormChange('domain', value)}
              >
                {dropdownValues.domains.sort().map((domain: string) => (
                  <Option key={domain} value={domain}>
                    {domain}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="platform"
              label={<Title level={5}>Platform <span style={{ color: 'red' }}>*</span></Title>}
              rules={[{ required: true, message: 'Please select a platform' }]}
            >
              <Select
                placeholder="Select Platform"
                onChange={(value) => handleInvoiceFormChange('platform', value)}
                disabled={!invoiceForm.domain}
              >
                {(dropdownValues.platformsByDomain[invoiceForm.domain] || []).sort().map((platform: string) => (
                  <Option key={platform} value={platform}>
                    {platform}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="partner"
              label={<Title level={5}>Partner</Title>}
            >
              <Select
                placeholder="Select Partner"
                onChange={(value) => handleInvoiceFormChange('partner', value)}
                disabled={!invoiceForm.platform}
              >
                {(dropdownValues.partnersByPlatform[invoiceForm.platform] || []).sort().map((partner: string) => (
                  <Option key={partner} value={partner}>
                    {partner}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="paymentDate"
              label={<Title level={5}>Payment Date <span style={{ color: 'red' }}>*</span></Title>}
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker
                placeholder="Select Date"
                format="MM/DD/YYYY"
                style={{ width: '100%' }}
                onChange={(date) => handleInvoiceFormChange('paymentDate', date ? date.format('MM/DD/YYYY') : '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="channel"
              label={<Title level={5}>Channel</Title>}
            >
              <Select
                placeholder="Select Channel"
                onChange={(value) => handleInvoiceFormChange('channel', value)}
                disabled={!invoiceForm.partner}
              >
                {(dropdownValues.channelsByPartner[invoiceForm.partner] || []).sort().map((channel: string) => (
                  <Option key={channel} value={channel}>
                    {channel}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="territory"
              label={<Title level={5}>Territory</Title>}
            >
              <Select
                placeholder="Select Territory"
                onChange={(value) => handleInvoiceFormChange('territory', value)}
                disabled={!invoiceForm.channel}
              >
                {(dropdownValues.platformsPartnersTerritories[`${invoiceForm.platform}_${invoiceForm.partner}`] || []).sort().map((territory: string) => (
                  <Option key={territory} value={territory}>
                    {territory}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="label"
              label={<Title level={5}>Label <span style={{ color: 'red' }}>*</span></Title>}
              rules={[{ required: true, message: 'Please select a label' }]}
            >
              <Select
                placeholder="Select Label"
                onChange={(value) => handleInvoiceFormChange('label', value)}
              >
                {dropdownValues.labels.sort().map((label: string) => (
                  <Option key={label} value={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="registerName"
              label={<Title level={5}>Register Name</Title>}
            >
              <Input
                placeholder="Register Name"
                onChange={(e) => handleInvoiceFormChange('registerName', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="lineItems"
              label={<Title level={5}>Line Items</Title>}
            >
              <Select
                placeholder="Line Items"
                onChange={(value) => handleInvoiceFormChange('lineItems', value)}
              >
                {Array.from({ length: 100 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: '24px' }}>
          <Button onClick={onCancel} style={{ marginRight: '8px' }}>Cancel</Button>
          <Button type="primary" htmlType="submit">Save</Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddInvoiceModal;
