import React, { useState, useEffect, useMemo } from 'react';
import { Card, Switch, Table, Button, Divider, Radio, Empty, Select } from 'antd';
import { CheckCircleFilled, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';  
import { ViewershipPoolProps, ViewershipRecord, GroupedViewershipData, AllocationStrategy, AllocationKey, AllocationPeriod } from '../../../../utils/common.interface';

type ColumnType = {
  title: string;
  dataIndex: string;
  key: AllocationKey | 'period' | 'select' | 'total_hov';
  width?: number;
  render?: (text: any, record: any) => React.ReactNode;
};

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    &:hover {
      color: #1890ff;
    }
    &-checked {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
      color: white !important;
      &::before {
        background-color: #1890ff !important;
      }
      &:hover {
        color: white !important;
      }
    }
  }
`;

const StyledTable = styled(Table)`
  .exact-match {
    background-color: #d9f7be !important; /* Light green */
  }

  .high-match {
    background-color: #e6f7ff !important; /* Light blue */
  }

  .medium-match {
    background-color: #fff1b8 !important; /* Light yellow */
  }

  .low-match {
    background-color: #ffccc7 !important; /* Light red */
  }

  .ant-table-row:hover > td {
    background-color: #bae7ff !important;
  }
`;

const ViewershipPool: React.FC<ViewershipPoolProps> = ({ 
  groupedData,
  selectedRecordKey,
  selectedRecord,
  allocationStrategy, 
  allocationPeriod,
  onSave,
}) => {
  const [localAllocationStrategy, setLocalAllocationStrategy] = useState<AllocationStrategy>(
    allocationStrategy || {
      platform: true,
      partner: true,
      channel: true,
      territory: true
    }
  );
  const [localAllocationPeriod, setLocalAllocationPeriod] = useState<AllocationPeriod>(allocationPeriod);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPoolId, setSelectedPoolId] = useState<string | null>(null);
  const [localSelectedPoolId, setLocalSelectedPoolId] = useState<string | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('All');

  const validKeys: AllocationKey[] = ['platform', 'partner', 'channel', 'territory'];

  const filteredAllocationStrategy = useMemo(() => 
    Object.fromEntries(
      Object.entries(localAllocationStrategy).filter(([key]) => validKeys.includes(key as AllocationKey))
    ) as Record<AllocationKey, boolean>,
    [localAllocationStrategy]
  );

  
  const getMatchScore = (record: any, selectedRecord: any, strategy: AllocationStrategy) => {
    let score = 0;
    let totalFields = 0;
  
    // Assign weights to each field
    const weights = {
      platform: 4,
      partner: 3,
      channel: 2,
      territory: 1,
    };
  
    if (strategy.platform && selectedRecord) {
      totalFields += weights.platform;
      if (record.platform.toLowerCase() === selectedRecord.platform.toLowerCase()) {
        score += weights.platform;
      }
    }
    if (strategy.partner && selectedRecord) {
      totalFields += weights.partner;
      if (record.partner.toLowerCase() === selectedRecord.partner.toLowerCase()) {
        score += weights.partner;
      }
    }
    if (strategy.channel && selectedRecord) {
      totalFields += weights.channel;
      if (record.channel.toLowerCase() === selectedRecord.channel.toLowerCase()) {
        score += weights.channel;
      }
    }
    if (strategy.territory && selectedRecord) {
      totalFields += weights.territory;
      if (record.territory.toLowerCase() === selectedRecord.territory.toLowerCase()) {
        score += weights.territory;
      }
    }
  
    return { score, totalFields };
  };
  

  const getRowClassName = (record: any) => {
    if (!selectedRecord) return '';
  
    const { score } = getMatchScore(record, selectedRecord, filteredAllocationStrategy);
  
    // Calculate the maximum possible score based on the strategy
    const maxPossibleScore = 
      (filteredAllocationStrategy.platform ? 4 : 0) +
      (filteredAllocationStrategy.partner ? 3 : 0) +
      (filteredAllocationStrategy.channel ? 2 : 0) +
      (filteredAllocationStrategy.territory ? 1 : 0);
  
    // Adjust class assignment based on the weighted score
    if (score === maxPossibleScore) return 'exact-match';  // Perfect match
    if (score >= maxPossibleScore - 1 && score >= maxPossibleScore * 0.75) return 'high-match';  // High match
    if (score >= maxPossibleScore * 0.5) return 'medium-match';  // Medium match
    if (score < maxPossibleScore * 0.5) return 'low-match';  // Low match
    return '';
  };
  
  const aggregateData = (data: GroupedViewershipData, strategy: AllocationStrategy, period: AllocationPeriod): any[] => {
    const aggregatedMap: Map<string, any> = new Map();
  
    Object.entries(data).forEach(([key, item]) => {
      const groupKey = validKeys
        .filter(k => strategy[k])
        .map(k => {
          switch(k) {
            case 'platform': return item.platform;
            case 'partner': return item.partner;
            case 'channel': return item.channel;
            case 'territory': return item.territory;
            default: return '';
          }
        })
        .join('|');
  
      if (period === 'Monthly') {
        Object.entries(item.time_periods).forEach(([timePeriod, hours]) => {
          const [year, month] = timePeriod.split('-');
          const fullGroupKey = `${groupKey}|${year}-${month}`;
          
          if (!aggregatedMap.has(fullGroupKey)) {
            aggregatedMap.set(fullGroupKey, {
              groupKey: fullGroupKey,
              platform: strategy.platform ? item.platform : 'All',
              partner: strategy.partner ? item.partner : 'All',
              channel: strategy.channel ? item.channel : 'All',
              territory: strategy.territory ? item.territory : 'All',
              period: `${year}-${month}`,
              total_hov: 0
            });
          }
          
          aggregatedMap.get(fullGroupKey).total_hov += hours;
        });
      } else { // Quarterly
        const quarterlyData: { [quarter: string]: number } = {};
        Object.entries(item.time_periods).forEach(([timePeriod, hours]) => {
          const [year, month] = timePeriod.split('-');
          const quarter = Math.floor((parseInt(month) - 1) / 3) + 1;
          const quarterKey = `${year}-Q${quarter}`;
          quarterlyData[quarterKey] = (quarterlyData[quarterKey] || 0) + hours;
        });
  
        Object.entries(quarterlyData).forEach(([quarterPeriod, hours]) => {
          const fullGroupKey = `${groupKey}|${quarterPeriod}`;
          
          if (!aggregatedMap.has(fullGroupKey)) {
            aggregatedMap.set(fullGroupKey, {
              groupKey: fullGroupKey,
              platform: strategy.platform ? item.platform : 'All',
              partner: strategy.partner ? item.partner : 'All',
              channel: strategy.channel ? item.channel : 'All',
              territory: strategy.territory ? item.territory : 'All',
              period: quarterPeriod,
              total_hov: 0
            });
          }
          
          aggregatedMap.get(fullGroupKey).total_hov += hours;
        });
      }
    });
  
    return Array.from(aggregatedMap.values());
  };
  useEffect(() => {
    if (allocationStrategy) {
      setLocalAllocationStrategy(allocationStrategy);
    }
    setLocalAllocationPeriod(allocationPeriod);
    setIsEditing(false); 
    setLocalSelectedPoolId(null); 
  }, [allocationStrategy, allocationPeriod, selectedRecordKey]); 

  const handleToggleChange = (field: AllocationKey) => (checked: boolean) => {
    setLocalAllocationStrategy(prev => ({
      ...prev,
      [field]: checked,
    }));
  };


  const sortedAndFilteredData = useMemo(() => {
    if (!groupedData || !selectedRecord) return [];
  
    const aggregatedData = aggregateData(groupedData, filteredAllocationStrategy, localAllocationPeriod);
  
    const filteredData = selectedPeriod === 'All' 
      ? aggregatedData 
      : aggregatedData.filter(item => item.period === selectedPeriod);
  
    const sortedData = filteredData.sort((a, b) => {
      const scoreA = getMatchScore(a, selectedRecord, filteredAllocationStrategy);
      const scoreB = getMatchScore(b, selectedRecord, filteredAllocationStrategy);
  
      if (scoreB.score !== scoreA.score) {
        return scoreB.score - scoreA.score;
      }
  
      // If scores are equal, sort by period
      if (localAllocationPeriod === 'Monthly') {
        return parseInt(a.period) - parseInt(b.period);
      } else {
        return a.period.localeCompare(b.period);
      }
    });
  
    return sortedData;
  }, [groupedData, selectedRecord, filteredAllocationStrategy, localAllocationPeriod, selectedPeriod]);
  
  
  const handlePeriodChange = (e: RadioChangeEvent) => {
    setLocalAllocationPeriod(e.target.value as AllocationPeriod);
  };

  const periodOptions = useMemo(() => {
    if (!sortedAndFilteredData.length) return [];
    const periods = Array.from(new Set(sortedAndFilteredData.map(item => item.period))).sort();
    return ['All', ...periods];
  }, [sortedAndFilteredData]);


  const handleEditSaveClick = () => {
    if (isEditing) {
      onSave(localAllocationStrategy, localAllocationPeriod, localSelectedPoolId || '');
      setSelectedPoolId(localSelectedPoolId);  // Update the actual selectedPoolId
      setIsEditing(false);
    } else {
      setIsEditing(true);
      setLocalSelectedPoolId(selectedPoolId);  // Initialize local selection with current selection
    }
  };



  const getColumns = (): ColumnType[] => {
    const baseColumns: ColumnType[] = validKeys
      .filter(key => filteredAllocationStrategy[key])
      .map(key => ({
        title: key.charAt(0).toUpperCase() + key.slice(1),
        dataIndex: key.toLowerCase(),
        width: 120,
        key: key,
      }));
  
    const periodColumn: ColumnType = {
      title: localAllocationPeriod === 'Monthly' ? 'Month' : 'Quarter',
      dataIndex: 'period',
      key: 'period',
    };
  
    // Insert period column after partner column
    const partnerColumnIndex = baseColumns.findIndex(col => col.key === 'partner');
    if (partnerColumnIndex !== -1) {
      baseColumns.splice(partnerColumnIndex + 1, 0, periodColumn);
    } else {
      baseColumns.push(periodColumn);
    }
  

    return [
      {
        title: 'Select',
        dataIndex: 'groupKey',
        key: 'select',
        width: 60,
        render: (key: string) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {key === (isEditing ? localSelectedPoolId : selectedPoolId) ? (
              <CheckCircleFilled style={{ color: '#1890ff', fontSize: '20px' }} />
            ) : (
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: '#d9d9d9',
              }} />
            )}
          </div>
        ),
      },
      ...baseColumns,
      {
        title: 'Tot. HOV',
        dataIndex: 'total_hov',
        key: 'total_hov',
        render: (hours: number) => hours.toFixed(2),
      }
    ];
  };

  const handlePoolSelection = (key: string) => {
    if (isEditing) {
      console.log('pool key: ', key)
      setLocalSelectedPoolId(key);
    }
  };

  return (
    <Card 
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Viewership Pool</span>
          <Button 
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            onClick={handleEditSaveClick}
            disabled={!selectedRecord}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
        </div>
      }
    >
      {selectedRecord ? (
        <>
          <div style={{ marginBottom: 16 }}>
            <h4>Allocate by:</h4>
            {validKeys.map(key => (
              <Switch
                key={key}
                checked={filteredAllocationStrategy[key]}
                onChange={handleToggleChange(key)}
                style={{ marginRight: 8 }}
                checkedChildren={key.charAt(0).toUpperCase() + key.slice(1)}
                unCheckedChildren={key.charAt(0).toUpperCase() + key.slice(1)}
                disabled={!isEditing}
              />
            ))}
          </div>
          <Divider />
          <div style={{ marginBottom: 16 }}>
            <h4>Period:</h4>
            <StyledRadioGroup onChange={handlePeriodChange} value={localAllocationPeriod} disabled={!isEditing}>
              <Radio.Button value="Monthly">Monthly</Radio.Button>
              <Radio.Button value="Quarterly">Quarterly</Radio.Button>
            </StyledRadioGroup>
          </div>
          <div style={{ marginBottom: 16 }}>
              <h4>{localAllocationPeriod === 'Monthly' ? 'Month' : 'Quarter'} Filter:</h4>
              <Select
                style={{ width: 120 }}
                value={selectedPeriod}
                onChange={(value) => setSelectedPeriod(value)}
              >
                {periodOptions.map(period => (
                   <Radio.Button key={period} value={period}>{period}</Radio.Button>
                ))}
              </Select>
            </div>
          <div style={{ width: '100%', overflowX: 'auto' }}>
            {sortedAndFilteredData.length > 0 ? (
              <StyledTable
                dataSource={sortedAndFilteredData}
                columns={getColumns()}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => isEditing && handlePoolSelection(record.groupKey),
                  className: getRowClassName(record),
                  style: { cursor: isEditing ? 'pointer' : 'default' }
                })}    
                rowClassName={(record) => getRowClassName(record)}
                scroll={{ x: 'max-content' }}
              />
            ) : (
              <Empty description="No matching data found for the selected record" />
            )}
          </div>
        </>
      ) : (
        <Empty 
          description={
            <span>
              Please select a record from the Allocation Details table to view the Viewership Pool
            </span>
          }
        />
      )}
    </Card>
  );
};

export default ViewershipPool;