import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { PaymentData } from '../types/main';
import { getUploadSignedURL } from '../../../utils/index';
import { authAxios } from "../../../utils/session_utils";

export const handleInputChange = (
  value: any,
  key: string,
  column: string,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  const newData = dataSource.map(item => (item.key === key ? { ...item, [column]: value } : item));
  setDataSource(newData);
};

export const handleAddLine = (
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  const newLine: PaymentData = {
    key: `${dataSource.length + 1}`,
    domain: '',
    platform: '',
    paymentDate: '',
    partner: '',
    channel: '',
    territory: '',
    amount: '',
    department: '',
    title: '',
    type: '',
    description: '',
    label: '',
    registerName: '',
    year: '',
    quarter: '',
    month: '',
    impressions: '',
    cpms: '',
    selected: false,
    allocationStrategy: {
      platform: true,
      partner: true,
      channel: true,
      territory: true,
      allocationStrategy: {} 
    },
    allocationLevel: '',
    allocationPeriod: '',
    linkedViewershipPool: '',
    linkedToViewership: false,
    toBeProcessed: true,
  };
  setDataSource([...dataSource, newLine]);
};

export const handleRemoveLine = (
  selectedRowKeys: React.Key[],
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>,
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const newData = dataSource.filter(item => !selectedRowKeys.includes(item.key));
  setDataSource(newData);
  setSelectedRowKeys([]);
};

export const handleSelectAll = (
  dataSource: PaymentData[],
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const allKeys = dataSource.map(item => item.key);
  setSelectedRowKeys(allKeys);
};


export const handleFileSelect = (
  fileName: string,
  fileId: string,
  key: string,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  setDataSource(prevDataSource =>
    prevDataSource.map(item =>
      item.key === key
        ? { ...item, fileName: fileName, fileId: fileId }
        : item
    )
  );
};

const generateJobId = () => {
  const timestamp = Date.now();
  const random = Math.floor(Math.random() * 1000);
  return `job_${timestamp}_${random}`;
};
  

export const getFilename = (uploadedFilename = '', jobId: string = generateJobId()) => {
  if (uploadedFilename) {
    const baseName = uploadedFilename.replace(/\.[^/.]+$/, '');
    return `${baseName}_${jobId}_payment_uploader.csv`;
  }
  return `${jobId}_payment_uploader.csv`;
};



// Update the handleUploadData function
export const handleUploadData = async (
  dataSource: PaymentData[],
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadStatus: React.Dispatch<React.SetStateAction<string>>,
  setOperationId: React.Dispatch<React.SetStateAction<string | null>>,
  setShowUploadView: React.Dispatch<React.SetStateAction<boolean>>,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onUploadSuccess: () => void,
  uploadedFilename?: string
) => {

  
  const finalFilename = getFilename(uploadedFilename);
  console.log('filename: ', finalFilename)
  // Reset states
  setIsUploading(true);
  setUploadStatus('initiating');
  setIsProgressModalVisible(true);
  setErrorMessage('');
  
  try {
    console.log("payment data: ", JSON.stringify(dataSource))
    // Validate data first
    const validationErrors = validatePaymentData(dataSource);
    if (validationErrors.length > 0) {
      throw new Error(validationErrors.join('\n'));
    }

    const { operationId } = await uploadPaymentData(dataSource, finalFilename);
    
    if (!operationId) {
      throw new Error('No operation ID received from server');
    }

    setOperationId(operationId);
    
    await pollUploadStatus(
      operationId, 
      setUploadStatus, 
      setIsUploading, 
      setShowUploadView, 
      setViewershipPool, 
      setIsProgressModalVisible, 
      setErrorMessage, 
      setIsErrorModalVisible,
      onUploadSuccess
    );
  } catch (error) {
    console.error('Error in upload process:', error);
    setIsUploading(false);
    setUploadStatus('error');
    setIsProgressModalVisible(false);
    
    // Handle different types of errors
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    }
    
    setErrorMessage(errorMessage);
    setIsErrorModalVisible(true);
    
    // Rethrow the error if needed for upper-level error handling
    throw error;
  }
};

export const uploadPaymentData = async (dataSource: PaymentData[], finalFilename: string) => {
  try {
    // Validate data before sending
    const validationErrors = validatePaymentData(dataSource);
    if (validationErrors.length > 0) {
      throw new Error(validationErrors.join('\n'));
    }

    console.log("before clean: ", JSON.stringify(dataSource));
    console.log("finalFilename: ", finalFilename)
    // Clean the data before sending
    const cleanedData = dataSource.map(row => ({
      ...row,
      amount: row.amount.trim(),
      partner: row.partner.trim(),
      channel: row.channel.trim(),
      territory: row.territory.trim(),
      filename: finalFilename,
      allocationStrategy: {
        platform: true,
        partner: true,
        channel: true,
        territory: true,
        allocationStrategy: {}
      }
    }));

    console.log("Cleaned data:", JSON.stringify(cleanedData));


    const response = await authAxios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/invoice_upload`,
      data: cleanedData,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.data || !response.data.operationId) {
      throw new Error('Invalid response from server');
    }

    return response.data;
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(error.response.data.message || 'Server error');
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response from server. Please check your connection.');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  }
};



export const pollOperationStatus = async (operationId: string) => {
  try {
    const response = await authAxios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
    });
    
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return { status: 'error', message: error.message };
    } else {
      return { status: 'error', message: 'An unknown error occurred' };
    }
  }
};


export const pollUploadStatus = async (
  operationId: string,
  setUploadStatus: (status: string) => void,
  setIsUploading: (isUploading: boolean) => void,
  setShowUploadView: (show: boolean) => void,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onUploadSuccess: () => void
) => {
  const pollInterval = 2000; 
  const maxAttempts = 30;
  let attempts = 0;

  console.log('polling...');
  console.log('op id: ', operationId);

  const poll = async () => {
    try {
      const data = await pollOperationStatus(operationId);
      console.log('Received data:', data);  

      setUploadStatus(data.status);

      switch (data.status) {
        case 'processing':
        case 'uploaded':
        case 'normalized':
        case 'checking_for_dupes':
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState('Operation timed out');
          }
          break;
        case 'viewership_fetched':
          setUploadStatus('viewership_fetched');
          setIsUploading(false);
          setShowUploadView(false);
          setViewershipPool(data.data);
          setIsProgressModalVisible(false);
          onUploadSuccess();  
          break;
        case 'error':
          handleErrorState(data.message || 'An error occurred during the operation');
          break;
        default:
          console.log(`Unexpected status: ${data.status}, continuing to poll`);
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
          }
      }
    } catch (error) {
      console.error('Error during polling:', error);
      attempts++;
      if (attempts < maxAttempts) {
        console.log(`Polling attempt ${attempts} failed. Retrying...`);
        setTimeout(poll, pollInterval);
      } else {
        handleErrorState('Polling failed after maximum attempts');
      }
    }
  };

  const handleErrorState = (errorMsg: string) => {
    setUploadStatus('error');
    setIsUploading(false);
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();
};


export const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'initiating': 'Initiating upload...',
    'processing': 'Processing data...',
    'uploaded': 'Data uploaded, validating...',
    'normalized': 'Normalizing data...',
    'viewership_fetched': 'Upload completed! Switching to allocation mode...',
    'completed': 'Upload completed!',
    'error': 'Error occurred'
  };
  return statusMap[status] || status;
};



const validatePaymentData = (data: PaymentData[]): string[] => {
  const errors: string[] = [];
  
  data.forEach((row, index) => {
    // Check for required fields
    if (!row.partner || row.partner.trim() === '') {
      errors.push(`Row ${index + 1}: Partner is required`);
    }
    if (!row.channel || row.channel.trim() === '') {
      errors.push(`Row ${index + 1}: Channel is required`);
    }
    if (!row.territory || row.territory.trim() === '') {
      errors.push(`Row ${index + 1}: Territory is required`);
    }
    if (!row.amount || row.amount.trim() === '') {
      errors.push(`Row ${index + 1}: Amount is required`);
    }
    
    // Validate amount format
    if (row.amount && isNaN(parseFloat(row.amount))) {
      errors.push(`Row ${index + 1}: Amount must be a valid number`);
    }

    // Ensure required date fields are present
    if (!row.year || row.year.trim() === '') {
      errors.push(`Row ${index + 1}: Year is required`);
    }
    if (!row.quarter || row.quarter.trim() === '') {
      errors.push(`Row ${index + 1}: Quarter is required`);
    }
    if (!row.month || row.month.trim() === '') {
      errors.push(`Row ${index + 1}: Month is required`);
    }

    // Validate allocationStrategy
    if (!row.allocationStrategy || typeof row.allocationStrategy !== 'object') {
      errors.push(`Row ${index + 1}: Invalid allocation strategy`);
    }
  });

  return errors;
};



export const handleAllocate = async (
  keys: string[], 
  dataSource: PaymentData[], 
  setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>
): Promise<boolean> => {
  const userEmail = window.sessionStorage.getItem("mvmgsid") || ""; 
  console.log("keys: ", keys)

  const selectedRecords = keys.map(key => {
    const record = dataSource.find(r => r.key === key);
    if (!record) {
      console.error(`No record found for key: ${key}`);
      return null;
    }
    return {
      key: record.key,
      platform: record.platform,
      partner: record.partner,
      channel: record.channel,
      territory: record.territory,
      amount: record.amount,
      paymentDate: record.paymentDate,
      allocationStrategy: record.allocationStrategy,
      allocationLevel: record.allocationLevel,
      allocationPeriod: record.allocationPeriod,
    };
  }).filter(record => record !== null);

  console.log("selectedRecords: ", selectedRecords)


  if (selectedRecords.length === 0) {
    console.error('No valid records selected for allocation');
    return false;
  }

  const payload = {
    selectedRecords,
    userEmail
  };

  setIsProgressModalVisible(true);

  try {
    const response = await authAxios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/allocate`,
      data: payload,
    });

    const { allocationOperationId } = response.data;

    pollAllocationStatus(
      allocationOperationId,
      setAllocationStatus,
      keys,
      setIsProgressModalVisible,
      setErrorMessage,
      setIsErrorModalVisible
    );

    return true;
  } catch (error) {
    console.error('Error during allocation:', error);
    setIsProgressModalVisible(false);
    setErrorMessage('Failed to initiate allocation process');
    setIsErrorModalVisible(true);
    return false;
  }
};



const pollAllocationStatus = async (
  operationId: string,
  setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
  keys: string[],
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let attempts = 0;
  const maxAttempts = 60;
  const pollInterval = 5000;

  const poll = async () => {
    try {
      const data = await pollAllocationStatusAPI(operationId);
      console.log('Received allocation data:', data);
      console.log('op id:', operationId);

      setAllocationStatus(data.status);

      switch (data.status) {
        case 'allocation_started':
        case 'pre_processing':
        case 'checking_rollup_table_for_dupes':
        case 'performing_rollups':
        case 'preparing_lambda_payloads':
        case 'invoking_lambda':
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState('Allocation operation timed out');
          }
          break;
        case 'allocation_completed':
          setIsProgressModalVisible(false);
          break;
        case 'error':
          handleErrorState(data.message || 'An error occurred during the allocation operation');
          break;
        default:
          console.log(`Unexpected allocation status: ${data.status}, continuing to poll`);
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
          }
      }
    } catch (error) {
      console.error('Error during allocation status polling:', error);
      attempts++;
      if (attempts < maxAttempts) {
        console.log(`Allocation polling attempt ${attempts} failed. Retrying...`);
        setTimeout(poll, pollInterval);
      } else {
        handleErrorState('Allocation polling failed after maximum attempts');
      }
    }
  };

  const handleErrorState = (errorMsg: string) => {
    setAllocationStatus('error');
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();
};
  
  const pollAllocationStatusAPI = async (operationId: string) => {
    try {
      const response = await authAxios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
      });
      
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      if (error instanceof Error) {
        return { status: 'error', message: error.message };
      } else {
        return { status: 'error', message: 'An unknown error occurred' };
      }
    }
  };







//s3 file handlers

export const handleSaveFile = async (
  currentFile: UploadFile | null,
  uploadUrls: { [key: string]: { url: string; fileId: string } },
  renamedFile: string,
  fileList: UploadFile[],
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  renamedFiles: { [key: string]: string },
  setRenamedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>,
  uploadedFiles: { [key: string]: boolean },
  setUploadedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const file = currentFile;
  if (!file) return;

  const { url, fileId } = uploadUrls[file.uid];
  const finalFileName = `${fileId}-${renamedFile}`;

  try {
    // Check if file was already uploaded
    if (!uploadedFiles[file.uid]) {
      await handleS3PaymentUpload(file, url, finalFileName); // Ensure the renamed file is used for uploading
      message.success('File uploaded successfully');
      setUploadedFiles(prev => ({ ...prev, [file.uid]: true })); // Mark file as uploaded
    }

    const newFileList = fileList.map(f =>
      f.uid === file.uid ? { ...f, name: renamedFile } : f // Update name to just renamedFile for display
    );
    setFileList(newFileList);

    const newRenamedFiles = { ...renamedFiles, [file.uid]: renamedFile };
    setRenamedFiles(newRenamedFiles);

    const newDataSource = dataSource.map(item =>
      item.key === file.uid ? { ...item, fileName: renamedFile, fileId } : item 
    );
    setDataSource(newDataSource);

  } catch (error) {
    message.error('Error uploading file');
  }

  setIsModalVisible(false);
};



export const handleS3PaymentUpload = async (
  file: UploadFile,
  url: string,
  fileName: string
) => {
  const response = await fetch(url, {
    method: 'PUT',
    body: file.originFileObj,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

};

export const handleFileChange = async (
  { file, fileList }: { file: UploadFile; fileList: UploadFile[] },
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  setCurrentFile: React.Dispatch<React.SetStateAction<UploadFile | null>>,
  setRenamedFile: React.Dispatch<React.SetStateAction<string>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadUrls: React.Dispatch<React.SetStateAction<{ [key: string]: { url: string; fileId: string } }>>,
  setFileId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  setFileList(fileList);
  setCurrentFile(file);
  setRenamedFile(file.name);
  setIsModalVisible(true);

  try {
    const response = await getUploadSignedURL('application/octet-stream', file.name); // Fetch presigned URL with original file name
    const { url, fileId } = response;
    setUploadUrls(prev => ({ ...prev, [file.uid]: { url, fileId } }));
    setFileId(fileId);
  } catch (error) {
    console.error('Error fetching pre-signed URL:', error);
  }
};
