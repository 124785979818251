
import React from 'react';
import { Button, DatePicker } from 'antd';
import { EyeOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { PaymentData } from '../types/main';
import dayjs from 'dayjs';

interface GetColumnsProps {
  visibleColumns: Record<string, boolean>;
  toggleColumnVisibility: (key: string) => void;
  renderCell: (value: string, record: PaymentData, column: keyof PaymentData) => React.ReactNode;
}

interface GetActionColumnProps {
  isEditing: (record: PaymentData) => boolean;
  edit: (record: PaymentData) => void;
  save: (key: string) => void;
  editingKey: string;
}

// Required fields should be marked with asterisk
const requiredFields = [
  'domain', 'platform', 'partner', 'channel', 'territory',
  'amount', 'paymentDate', 'year', 'quarter', 'month', 'label'
];



const createColumnConfig = (
  key: keyof PaymentData,
  title: string,
  width = 150,            // Removed ": number"
  required = false,       // Removed ": boolean"
  toggleColumnVisibility: (key: string) => void
) => ({
  key,
  title: (
    <div className="flex items-center gap-2">
      <span className="whitespace-nowrap">{title}{required && '*'}</span>
      <Button 
        icon={<EyeOutlined />} 
        size="small"
        onClick={() => toggleColumnVisibility(key)}
      />
    </div>
  ),
  dataIndex: key,
  width,
  required
});

export const getColumns = ({
  visibleColumns,
  toggleColumnVisibility,
  renderCell
}: GetColumnsProps) => {
  const baseColumns = [
    createColumnConfig('domain', 'Domain', 150, true, toggleColumnVisibility),
    createColumnConfig('platform', 'Platform', 150, true, toggleColumnVisibility),
    createColumnConfig('partner', 'Partner', 150, true, toggleColumnVisibility),
    createColumnConfig('channel', 'Channel', 120, true, toggleColumnVisibility),
    createColumnConfig('territory', 'Territory', 120, true, toggleColumnVisibility),
    createColumnConfig('amount', 'Amount', 120, true, toggleColumnVisibility),
    createColumnConfig('paymentDate', 'Payment Date', 150, true, toggleColumnVisibility),
    createColumnConfig('year', 'Year', 100, true, toggleColumnVisibility),
    createColumnConfig('quarter', 'Quarter', 100, true, toggleColumnVisibility),
    createColumnConfig('month', 'Month', 100, true, toggleColumnVisibility),
    createColumnConfig('label', 'Label', 120, true, toggleColumnVisibility),
    createColumnConfig('department', 'Department', 120, false, toggleColumnVisibility),
    createColumnConfig('title', 'Title', 150, false, toggleColumnVisibility),
    createColumnConfig('type', 'Type', 120, false, toggleColumnVisibility),
    createColumnConfig('description', 'Description', 200, false, toggleColumnVisibility),
    createColumnConfig('registerName', 'Register Name', 150, false, toggleColumnVisibility),
    createColumnConfig('impressions', 'Impressions', 120, false, toggleColumnVisibility),
    createColumnConfig('cpms', 'CPMs', 120, false, toggleColumnVisibility)
  ];

  return baseColumns.map(col => ({
    ...col,
    className: !visibleColumns[col.key] ? 'collapsed-column' : '',
    render: (text: string, record: PaymentData) => {
      if (!visibleColumns[col.key]) {
        return <div className="w-8 opacity-0">•</div>;
      }

      // Special handling for payment date
      if (col.key === 'paymentDate' && text) {
        return renderCell(dayjs(text).format('YYYY-MM-DD'), record, col.key);
      }

      return renderCell(text, record, col.key);
    },
    width: visibleColumns[col.key] ? col.width : 40
  }));
};



export const getActionColumn = ({
  isEditing,
  edit,
  save,
  editingKey
}: GetActionColumnProps) => ({
  title: 'Actions',
  key: 'actions',
  fixed: 'right' as const,
  width: 100,
  render: (_: any, record: PaymentData) => {
    const editable = isEditing(record);
    return editable ? (
      <Button onClick={() => save(record.key)} icon={<SaveOutlined />} />
    ) : (
      <Button 
        onClick={() => edit(record)} 
        icon={<EditOutlined />} 
        disabled={editingKey !== ''} 
      />
    );
  }
});


export const tableStyles = `
  .collapsed-column {
    padding: 0 !important;
    width: 40px !important;
    max-width: 40px !important;
    border-right: 1px solid #f0f0f0;
    overflow: hidden;
  }
  
  .collapsed-column .ant-table-cell {
    padding: 8px 4px !important;
    transition: all 0.3s ease;
  }

  .ant-table-cell {
    transition: width 0.3s ease, padding 0.3s ease;
  }

  .required-field::after {
    content: '*';
    color: #ff4d4f;
    margin-left: 4px;
  }
`;
