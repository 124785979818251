import React, { useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import AllocationDetailsTable from './AllocationDetailsTable';
import ViewershipPool from './ViewershipPool';
import { AllocationPeriod, AllocationStrategy, PaymentAllocationManagerProps, ViewershipPoolProps } from '../../../../utils/common.interface';
import { PaymentData , GroupedViewershipData } from '../../types/main';
import { useViewershipPool } from '../../hooks/useViewershipPool';


const isValidAllocationPeriod = (value: any): value is AllocationPeriod => {
  return value === 'Monthly' || value === 'Quarterly';
};

const AllocationManager: React.FC<PaymentAllocationManagerProps> = ({
  dataSource,
  setDataSource,
  handleAllocate,
  linkViewershipPoolToRecord,
  selectedRecord,  
  setSelectedRecord,
  viewershipPool, 
  allocationStatus
    
}) => {
  const [pendingChanges, setPendingChanges] = useState<Partial<PaymentData>>({});
  const { getGroupedData } = useViewershipPool(viewershipPool || {});
  
  
  const groupedData = useMemo(() => {
    if (!viewershipPool) {
      return null;
    }
    const result = getGroupedData(['platform', 'partner', 'channel', 'territory', 'year', 'quarter']);
    return result;
  }, [getGroupedData, viewershipPool]);


  const handleViewershipPoolSave = (newAllocationStrategy: AllocationStrategy, newAllocationPeriod: AllocationPeriod, selectedPoolId: string) => {
    if (selectedRecord) {
      const updatedRecord: PaymentData = {  
        ...selectedRecord,
        allocationStrategy: newAllocationStrategy,
        allocationPeriod: newAllocationPeriod,
        linkedToViewership: true,
        linkedViewershipPool: selectedPoolId
      };
      setDataSource(prevDataSource => 
        prevDataSource.map(record => 
          record.key === selectedRecord.key ? updatedRecord : record
        )
      );
      setSelectedRecord(updatedRecord);
    }
  };

  const handleRecordSelection = (record: PaymentData | null) => {
    setSelectedRecord(record);  
    setPendingChanges({});
  };
  
  if (!groupedData) {
    return <div>Loading viewership data...</div>;
  }
  

  const viewershipPoolProps: ViewershipPoolProps = {
    groupedData: groupedData || {},
    selectedRecord: selectedRecord || null,
    selectedRecordKey: selectedRecord?.key || null,
    allocationStrategy: selectedRecord?.allocationStrategy || {
      platform: true,
      partner: true,
      channel: true,
      territory: true
    },
    allocationLevel: selectedRecord?.allocationLevel || null,
    allocationPeriod: selectedRecord?.allocationPeriod && isValidAllocationPeriod(selectedRecord.allocationPeriod) 
      ? selectedRecord.allocationPeriod 
      : 'Monthly',    
    onSave: handleViewershipPoolSave,
    linkViewershipPoolToRecord,
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <AllocationDetailsTable
          dataSource={dataSource}
          setDataSource={setDataSource}
          handleAllocate={handleAllocate}
          onRecordSelect={handleRecordSelection}
          selectedRecord={selectedRecord}
          allocationStatus={allocationStatus}
        />
      </Col>
      <Col span={12}>
       <ViewershipPool {...viewershipPoolProps} />
      </Col>
    </Row>
  );
};


export default AllocationManager;