import { UploadFile, RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { PaymentData } from '../types/main';  // Assuming you have this type defined

// Helper function to clean headers
export const cleanHeader = (header: string): string => {
  return header
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s_]/g, '')
    .replace(/\s+/g, '_');
};

// Helper function to format row data
export const formatRowData = (row: Record<string, unknown>, index: number): PaymentData => ({
  key: index.toString(),
  domain: row.domain?.toString() || '',
  platform: row.platform?.toString() || '',
  partner: row.partner?.toString() || '',
  channel: row.channel?.toString() || '',
  territory: row.territory?.toString() || '',
  amount: row.amount?.toString() || '',
  department: row.department?.toString() || '',
  title: row.title?.toString() || '',
  type: row.type?.toString() || '',
  description: row.description?.toString() || '',
  label: row.label?.toString() || '',
  registerName: row.register_name?.toString() || '',
  year: row.year?.toString() || '',
  quarter: row.quarter?.toString() || '',
  month: row.month?.toString() || '',
  impressions: row.impressions?.toString() || '',
  cpms: row.cpms?.toString() || '',
  paymentDate: row.payment_date?.toString() || '',
  allocationStrategy: {
    platform: true,
    partner: true,
    channel: true,
    territory: true,
    allocationStrategy: {}
  },
  allocationLevel: row.allocation_level?.toString() || '',
  allocationPeriod: row.allocation_period?.toString() || '',
  linkedViewershipPool: '',
  linkedToViewership: false,
  selected: false,
  toBeProcessed: true,
  filename: ''
});

// Handle CSV file parsing
const handleCSVFile = async (
  fileObj: Blob
): Promise<PaymentData[]> => {  
  const text = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Failed to read file as text'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(fileObj);
  });

  return new Promise((resolve, reject) => {
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      transformHeader: cleanHeader,
      complete: (results) => {
        const formattedData = results.data.map((row: unknown, index) => 
          formatRowData(row as Record<string, unknown>, index)
        );
        console.log("uploaded data: ", JSON.stringify(formattedData))
        resolve(formattedData);
      },
      error: (error: any) => {
        console.error('CSV parsing error:', error);
        reject(error);
      }
    });
  });
};
  
// Handle Excel file parsing
const handleExcelFile = async (fileObj: Blob): Promise<PaymentData[]> => {
  const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        resolve(result);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsArrayBuffer(fileObj);
  });

  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(firstSheet) as Record<string, unknown>[];

  const cleanedData = jsonData.map(row => {
    const cleanedRow: Record<string, unknown> = {};
    Object.entries(row as Record<string, unknown>).forEach(([key, value]) => {
      cleanedRow[cleanHeader(key)] = value;
    });
    return cleanedRow;
  });

  return cleanedData.map((row, index) => formatRowData(row, index));
};

// Import .csv or excel file
export const handleDataFileUpload = async (
  file: UploadFile | RcFile,
  setDataSource: (data: PaymentData[]) => void,
  setUploadedFilename: (filename: string) => void
): Promise<boolean> => {
  try {
    const fileObj = 'originFileObj' in file ? file.originFileObj : file;
    if (!fileObj) {
      message.error('Invalid file object');
      return false;
    }

    const fileExtension = file.name?.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      message.error('Invalid file extension');
      return false;
    }
    
    if (file.name) {
      setUploadedFilename(file.name);
    }

    let formattedData: PaymentData[];
    
    if (fileExtension === 'csv') {
      formattedData = await handleCSVFile(fileObj as Blob);
      message.success('CSV data loaded successfully');
    } else if (['xlsx', 'xls'].includes(fileExtension)) {
      formattedData = await handleExcelFile(fileObj as Blob);
      message.success('Excel data loaded successfully');
    } else {
      message.error('Unsupported file type');
      return false;
    }
    
    setDataSource(formattedData);
    return false;
  } catch (error) {
    console.error('File processing error:', error);
    message.error('Error processing file');
    return false;
  }
};