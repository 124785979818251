import React, { useState } from 'react';
import { Card, Upload, Table, Button, Switch, message, Select, Input, Space, Row, Col, Dropdown, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { useDropdownValues , usePaymentManager} from '../hooks/usePaymentManager';
import { getColumns, getActionColumn} from '../helpers/columns';
import { PaymentData } from '../types/main';
import { handleUploadData, getStatusText, handleAllocate } from '../helpers/tableFunctions';
import { handleDataFileUpload } from '../helpers/fileHandlers';
import AllocationManager from './AllocationManager/AllocationManager';
import UploadProgress from './UploadProgress'
const { Option } = Select;


const PaymentManagement: React.FC = () => {
  const [mode, setMode] = useState<'upload' | 'allocation'>('upload');
  const [dataSource, setDataSource] = useState<PaymentData[]>([]);
  const [editingKey, setEditingKey] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [operationId, setOperationId] = useState<string | null>(null);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showUploadView, setShowUploadView] = useState(true);
  const [viewershipPool, setViewershipPool] = useState<any>(null);
  const [allocationReady, setAllocationReady] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<PaymentData | null>(null);
  const [allocationStatus, setAllocationStatus] = useState<string>('initial');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [jobId, setJobId] = useState('');
  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>({
    domain: true,
    platform: true,
    partner: true,
    amount: true,
    channel: true,
    territory: true,
    department: true,
    title: true,
    type: true,
    description: true,
    label: true,
    registerName: true,
    year: true,
    quarter: true,
    month: true,
    impressions: true,
    cpms: true,
    paymentDate: true,
});
  const dropdownValues = useDropdownValues();
  const toggleColumnVisibility = (columnKey: string) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey]
    }));
  };

  const handleModeChange = (checked: boolean) => {
    setMode(checked ? 'allocation' : 'upload');
  };

  const isEditing = (record: PaymentData) => record.key === editingKey;

  const edit = (record: PaymentData) => {
    setEditingKey(record.key);
  };

  const save = (key: string) => {
    const row = dataSource.find(item => item.key === key);
    if (row) {
      setDataSource(prev => prev.map(item => 
        item.key === key ? row : item
      ));
    }
    setEditingKey('');
  };

  const handleInputChange = (value: string, key: string, column: keyof PaymentData) => {
    setDataSource(prev => prev.map(item => 
      item.key === key ? { ...item, [column]: value } : item
    ));
  };

  const onFileUpload = (file: UploadFile | RcFile) => {
    return handleDataFileUpload(file, setDataSource, setUploadedFilename);
  };




  const renderCell = (value: string, record: PaymentData, column: keyof PaymentData) => {
    const editing = isEditing(record);
    if (editing) {
      if (column === 'domain') {
        return (
          <Select
            value={value}
            onChange={(val) => handleInputChange(val, record.key, column)}
            style={{ width: '100%' }}
          >
            {dropdownValues.domains.sort().map((domain) => (
              <Option key={domain} value={domain}>{domain}</Option>
            ))}
          </Select>
        );
      } else if (column === 'platform') {
        return (
          <Select
            value={value}
            onChange={(val) => handleInputChange(val, record.key, column)}
            style={{ width: '100%' }}
            disabled={!record.domain}
          >
            {(dropdownValues.platformsByDomain[record.domain] || []).sort().map((platform) => (
              <Option key={platform} value={platform}>{platform}</Option>
            ))}
          </Select>
        );
      } else if (column === 'partner') {
        return (
          <Select
            value={value}
            onChange={(val) => handleInputChange(val, record.key, column)}
            style={{ width: '100%' }}
            disabled={!record.platform}
          >
            {(dropdownValues.partnersByPlatform[record.platform] || []).sort().map((partner) => (
              <Option key={partner} value={partner}>{partner}</Option>
            ))}
          </Select>
        );
      } else if (column === 'label') {
        return (
          <Select
            value={value}
            onChange={(val) => handleInputChange(val, record.key, column)}
            style={{ width: '100%' }}
          >
            <Option value="Revenue">Revenue</Option>
            <Option value="Expense">Expense</Option>
          </Select>
        );
      }
      return (
        <Input
          value={value}
          onChange={(e) => handleInputChange(e.target.value, record.key, column)}
          style={{ width: '100%' }}
        />
      );
    }
    return value;
  };

  const columns = getColumns({
    visibleColumns,
    toggleColumnVisibility,
    renderCell
  });

  const actionColumn = getActionColumn({
    isEditing,
    edit,
    save,
    editingKey
  });


  const handleUploadSuccess = () => {
    setMode('allocation');
    setAllocationReady(true);
    message.success('Upload completed successfully! Switching to allocation mode.');
  };


  const handleInitiateAllocate = async (keys: string[]) => {
    setAllocationStatus('processing');
    try {
      await handleAllocate(
        keys, 
        dataSource, 
        setAllocationStatus,
        setIsProgressModalVisible,
        setErrorMessage,
        setIsErrorModalVisible
      );
      setAllocationStatus('success');
      return true;
    } catch (error) {
      setAllocationStatus('failure');
      return false;
    }
  };
  const linkViewershipPoolToRecord = (recordKey: string, poolId: string) => {
    setDataSource(prev => prev.map(record => 
      record.key === recordKey 
        ? { ...record, linkedViewershipPool: poolId, linkedToViewership: true }
        : record
    ));
  };


  return (
    <div className="p-4">
      <Card className="mb-4">

{/* Header with Mode Selection */}
<Row className="w-full mb-6" align="middle" justify="space-between">
            <Col>
              <h1 className="text-2xl font-bold">Payment Management</h1>
            </Col>
            <Col>
              <Card size="small" className="bg-gray-50 border border-gray-200">
                <Space>
                  <span className="font-medium">Mode:</span>
                  <Switch
                    checked={mode === 'allocation'}
                    onChange={handleModeChange}
                    checkedChildren="Allocation"
                    unCheckedChildren="Upload"
                  />
                </Space>
              </Card>
            </Col>
          </Row>

          {/* Action Buttons Row */}
          <Row className="w-full">
            <Col span={12}>
              <Space size="middle">
                <Upload
                  accept=".csv,.xlsx,.xls"
                  beforeUpload={onFileUpload}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Import Payment Data</Button>
                </Upload>

                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'show-hide-all',
                        label: (
                          <div className="px-2 pb-2 border-b border-gray-200">
                            <Button
                              type="text"
                              onClick={() => {
                                const allHidden = Object.values(visibleColumns).every(v => v);
                                setVisibleColumns(prev => 
                                  Object.keys(prev).reduce((acc, key) => ({
                                    ...acc,
                                    [key]: !allHidden
                                  }), {})
                                );
                              }}
                            >
                              {Object.values(visibleColumns).every(v => v) ? 'Hide All' : 'Show All'}
                            </Button>
                          </div>
                        )
                      },
                      ...Object.entries(visibleColumns).map(([key, value]) => ({
                        key,
                        label: (
                          <div className="flex items-center gap-2 px-2 py-1">
                            <Switch
                              size="small"
                              checked={value}
                              onChange={() => toggleColumnVisibility(key)}
                            />
                            <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                          </div>
                        )
                      }))
                    ]
                  }}
                  trigger={['click']}
                >
                  <Button>Column Settings</Button>
                </Dropdown>
              </Space>
            </Col>
            
            <Col span={12} style={{ textAlign: 'right' }}>
              {mode === 'upload' && (
                <Button
                  onClick={() => handleUploadData(
                    dataSource,
                    setIsUploading,
                    setUploadStatus,
                    setOperationId,
                    setShowUploadView,
                    setViewershipPool,
                    setIsProgressModalVisible,
                    setErrorMessage,
                    setIsErrorModalVisible,
                    handleUploadSuccess,
                    uploadedFilename
                  )}
                  disabled={dataSource.length === 0 || isUploading}
                  loading={isUploading}
                  type="primary"
                  className="bg-blue-600 hover:bg-blue-500"
                >
                  Upload to Database
                </Button>
              )}
            </Col>
          </Row>

  
        {/* Table Section */}

        {/* Content Section */}
        {mode === 'upload' ? (
          /* Upload View */
          <Table
            dataSource={dataSource}
            columns={[...columns, actionColumn]}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
        ) : (
          /* Allocation View */
          <AllocationManager
            dataSource={dataSource}
            setDataSource={setDataSource}
            handleAllocate={handleInitiateAllocate}
            linkViewershipPoolToRecord={linkViewershipPoolToRecord}
            selectedRecord={selectedRecord}
            setSelectedRecord={setSelectedRecord}
            viewershipPool={viewershipPool}
            allocationStatus={allocationStatus}
          />
        )}
      </Card>
  
      {/* Modals */}
      <Modal
        title="Upload Progress"
        open={isProgressModalVisible}
        footer={null}
        closable={false}
      >
        <div className="text-center">
          <UploadProgress uploadStatus={uploadStatus} />
          <p className="mt-4">{getStatusText(uploadStatus)}</p>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentManagement;
