import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Select, Flex, message } from 'antd';
import { useBatchJobsActionHook } from './hooks/useBatchJobsActionHook';

interface BatchJobActionComponentProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  record: any;
}

const BatchJobActionComponent: React.FC<BatchJobActionComponentProps> = ({ setIsLoading, record }) => {
  const { handleUploadSFClick } = useBatchJobsActionHook(setIsLoading);
  const [ sfUploadDisabled, setSFUploadDisabled ] = useState<boolean>(true);
  const [ sfUploadProgramErrors, setSFUploadProgramErrors ] = useState<string[]>([]);
  const [planOptions, setPlanOptions] = useState<{ value: string; label: string }[]>([]);
  const [programOptions, setProgramOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if(record.job_result_json === "" || record.job_result_json === null) {
      return;
    }
    if(record.processing_info_json === "" || record.processing_info_json === null) {
      return;
    } 
    const json_result = JSON.parse(record.job_result_json);
    const json_processing_info = JSON.parse(record.processing_info_json);
    
    const _planOptions = Object.keys(json_result.data).map((key) => {
      return { value: key, label: key };
    });
    setPlanOptions(_planOptions);
    const _programOptions = json_processing_info.programs.map((key: string) => {
      return { value: key, label: key };
    });
    setProgramOptions(_programOptions);
    if(_programOptions.length != 1) {
      setSFUploadDisabled(true);
    } else {
      setSFUploadDisabled(false);
    }

    if(json_processing_info.errors['programs'].length > 0) {
      setSFUploadProgramErrors(json_processing_info.errors['programs']);
    }
  }, [record]);
  

  if(record.job_result_json === "" || record.job_result_json === null) {
    return <Flex vertical={true}>{null}</Flex>;
  }
  if(record.processing_info_json === "" || record.processing_info_json === null) {
    return <Flex vertical={true}>{null}</Flex>;
  }

  return (
    <Flex vertical={true}>
      <Select placeholder="Select a plan" options={planOptions} onChange={(value) => {
        const buttonSFUpload = document.getElementById(`buttonSFUpload_${record.id}`);
        if(buttonSFUpload) {
          buttonSFUpload.setAttribute("data-strategy-name", value);
        }
      }} />
      <Select placeholder="Select program" options={programOptions} onChange={(value) => {
        const buttonSFUpload = document.getElementById(`buttonSFUpload_${record.id}`);
        if(buttonSFUpload) {
          buttonSFUpload.setAttribute("data-program-name", value);
        }
      }} />
      <Button type="primary" id={`buttonSFUpload_${record.id}`} onClick={(element) => {
        const strategyName = element.currentTarget.getAttribute("data-strategy-name");
        const programName = element.currentTarget.getAttribute("data-program-name");
        if(strategyName && programName) {
          setIsLoading(true);
          handleUploadSFClick({ strategyName, programName, batchJobId: record.id });
        } else {
          message.error("Please select a plan and a program");
        }
      }} disabled={sfUploadDisabled}>SF Upload</Button>
      {sfUploadProgramErrors.length > 0 && <Flex vertical={true}>
        {sfUploadProgramErrors.map((error: string) => {
          return <p style={{ color: 'red' }} key={error}>Error: {error}</p>;
        })}
      </Flex>}
    </Flex>
  );
};

export default BatchJobActionComponent;